import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link, ListItem, NumberedList } from '@entur/typography';
import { CodeText } from '@entur/typography';
import './guides.scss';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "product-evaluation-guide"
    }}>{`Product Evaluation Guide`}</h1>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <NumberedList className="offers-guides-toc" mdxType="NumberedList">
    <ListItem mdxType="ListItem">
        <Link href="#conditions-for-a-product-to-be-valid-for-offering" mdxType="Link">
            Conditions for a product to be valid for offering
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#validity-overview" mdxType="Link">
            Validity overview
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#implementations" mdxType="Link">
            Implementations
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#placement-and-structure" mdxType="Link">
            Placement and structure
        </Link>
        <NumberedList className="offers-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#types-of-gpa-nodes-and-what-they-can-do-and-contain" mdxType="Link">
                    Types of GPA nodes and what they can do and contain
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#conditions-and-choices" mdxType="Link">
                    Conditions and Choices
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#special-rules-and-prerequisites-worth-noting" mdxType="Link">
                    Special rules and prerequisites worth noting
                </Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#context" mdxType="Link">
            Context
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#data-types" mdxType="Link">
            Data Types
        </Link>
        <NumberedList className="offers-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#data-types-for-usage-parameters-up" mdxType="Link">
                    Data Types for Usage Parameters (UP)
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#data-types-for-temporal-validity-parameters-tvp" mdxType="Link">
                    Data Types for Temporal Validity Parameters (TVP)
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#data-types-for-scoping-validity-parameters-svp" mdxType="Link">
                    Data Types for Scoping Validity Parameters (SVP)
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#data-types-that-are-handled-in-the-various-endpoints" mdxType="Link">
                    Data Types that are handled in the various endpoints
                </Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#operators" mdxType="Link">
            Operators
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#ignore-flag-canignoreinoffering" mdxType="Link">
            Ignore flag 'canIgnoreInOffering'
        </Link>
    </ListItem>
    </NumberedList>
    <h2 {...{
      "id": "conditions-for-a-product-to-be-valid-for-offering"
    }}>{`Conditions for a product to be valid for offering`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`This guide attempts to describe how offers determines what products are to be offered, through the use of `}<strong parentName="p">{`Generic Parameter Assignments (GPA)`}</strong>{`.`}</p>
    <p><strong parentName="p">{`NeTEx`}</strong>{` terminology and types are used both in offers and this documentation.
You can read more about NeTEx in the `}<a parentName="p" {...{
        "href": "https://enturas.atlassian.net/wiki/spaces/PUBLIC/pages/728563782/General+information+NeTEx"
      }}>{`Entur NeTEx documentation`}</a>{` or their `}<a parentName="p" {...{
        "href": "https://netex-cen.eu/"
      }}>{`official website`}</a>{`.`}</p>
    <h3 {...{
      "id": "validity-overview"
    }}>{`Validity overview`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`What controls what products are to be offered (and to what price) is based on generic, mostly quantitative,
validity limitation rules which consist in assigning certain “limiting parameters” to specific access rights.`}</p>
    <p><img alt="Diagram of the relation between generic parameter assignments, validity limitation and products. Details are available in the text below." src={require("./diagram_validity_rules.png")} /></p>
    <p><strong parentName="p">{`Generic Parameter Assignments (GPA)`}</strong>{` are used to set these limitations. They specify generic (theoretical) access rights for a class of products,
and describes `}<strong parentName="p">{`pre- and post- conditions/rules for a product to be valid for offering`}</strong>{` (e.g. a time band limit - 7 to 10 a.m. - for trips made with a student pass).
They indicate a theoretical set of possible limitation parameters that apply to a product. a GPA can be connected to any level of a product definition, i.e. `}<em parentName="p">{`Sales Package`}</em>{`, `}<em parentName="p">{`Fare Product`}</em>{`, `}<em parentName="p">{`Validable Element`}</em>{` or `}<em parentName="p">{`Fare Structure Element`}</em>{`.`}</p>
    <p>{`GPAs are a `}<em parentName="p">{`subtype`}</em>{` of `}<strong parentName="p">{`Validity Parameter Assignment (VPA)`}</strong>{`. Another sub-type of VPA is `}<strong parentName="p">{`Specific Parameter Assignment (SPA)`}</strong>{`,
which assigns a limiting parameter to a particular right, thus representing the choice of a specific set of parameters for consumption on an individual trip.`}</p>
    <p><strong parentName="p">{`The limitation parameters of a GPA can be of the following types:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Usage Parameters (UP)`}</strong>{`, that contain limitations/rules mainly linked to the characteristics of the `}<strong parentName="li"><em parentName="strong">{`actual/practical use of access rights`}</em></strong>{`,
for e.g. `}<em parentName="li">{`User Profiles`}</em>{`, `}<em parentName="li">{`Group`}</em>{`, `}<em parentName="li">{`Refund`}</em>{`, `}<em parentName="li">{`Reserving`}</em>{`, `}<em parentName="li">{`Usage Validity Period`}</em>{`, `}<em parentName="li">{`Interchanging`}</em>{`, `}<em parentName="li">{`Purchase Window`}</em>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Temporal Validity Parameters (TVP)`}</strong>{`, reflecting temporal limitations, contain validity limitations/rules for `}<strong parentName="li"><em parentName="strong">{`time management`}</em></strong>{`,
e.g. `}<em parentName="li">{`Availability Conditions`}</em>{` with `}<em parentName="li">{`Day Type`}</em>{` and `}<em parentName="li">{`Timeband`}</em>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Scoping Validity Parameters (SVP)`}</strong>{`, reflecting spatial and consumption limitations, contain validity limitations/rules for `}<strong parentName="li"><em parentName="strong">{`scoping`}</em></strong>{`,
e.g. elements like `}<em parentName="li">{`Authority`}</em>{`, `}<em parentName="li">{`Submodes`}</em>{`, `}<em parentName="li">{`Lines`}</em>{`, `}<em parentName="li">{`Zones`}</em>{`, `}<em parentName="li">{`Fare Sections`}</em>{`.`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`A GPA contains information about:`}</strong></p>
    <ul>
      <li parentName="ul">{`What specific `}<strong parentName="li"><em parentName="strong">{`limitation parameters (rules)`}</em></strong>{` the GPA includes, either of types `}<em parentName="li">{`Usage Parameters`}</em>{` or `}<em parentName="li">{`Validity Parameters`}</em>{` `}<em parentName="li">{`(Temporal or Scoping)`}</em>{`.`}</li>
      <li parentName="ul">{`What `}<strong parentName="li"><em parentName="strong">{`marketable/priceable object`}</em></strong>{` the GPA is related to, which include `}<em parentName="li">{`Sales Package`}</em>{`, `}<em parentName="li">{`Preassigned Fare Product`}</em>{`,
`}<em parentName="li">{`Validable Element`}</em>{`, `}<em parentName="li">{`Fare Structure Element`}</em>{`, or a `}<em parentName="li">{`parent GPA`}</em>{` (see `}<a parentName="li" {...{
          "href": "#placement-and-structure"
        }}>{`Placement and structure`}</a>{`).`}</li>
      <li parentName="ul">{`How the underlying `}<strong parentName="li"><em parentName="strong">{`parameters`}</em></strong>{` are to be `}<strong parentName="li"><em parentName="strong">{`validated`}</em></strong>{`, through operators set on `}<em parentName="li">{`Includes Grouping Type`}</em>{`,
`}<em parentName="li">{`Limitation Grouping Type`}</em>{`, `}<em parentName="li">{`Validity Parameter Grouping Type`}</em>{` and `}<em parentName="li">{`Validity Parameter Assignment Type`}</em>{` (see `}<a parentName="li" {...{
          "href": "#operators"
        }}>{`Operators`}</a>{`).`}</li>
      <li parentName="ul">{`If the GPA will be `}<strong parentName="li"><em parentName="strong">{`ignoring unsupported data types`}</em></strong>{` and values in endpoints that does not evaluate the data type,
so that an offer can still be made (see `}<a parentName="li" {...{
          "href": "#ignore-flag-canIgnoreInOffering"
        }}>{`Ignore flag 'canIgnoreInOffering'`}</a>{`).`}</li>
      <li parentName="ul">{`If the GPA is `}<strong parentName="li"><em parentName="strong">{`required in context`}</em></strong>{` (`}<inlineCode parentName="li">{`requiredInContext`}</inlineCode>{`), has a `}<strong parentName="li"><em parentName="strong">{`required type`}</em></strong>{` (`}<inlineCode parentName="li">{`requiredType`}</inlineCode>{`) or has `}<strong parentName="li"><em parentName="strong">{`required elements`}</em></strong>{` (`}<inlineCode parentName="li">{`requiredElements`}</inlineCode>{`) (see `}<a parentName="li" {...{
          "href": "#context"
        }}>{`Context`}</a>{`).`}</li>
    </ul>
    <br />
    <h3 {...{
      "id": "implementations"
    }}>{`Implementations`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`There are currently two different GPA implementations used in offers, with some differences in how different GPAs are evaluated.`}</p>
    <h4 {...{
      "id": "how-a-gpa-implementation-is-selected-in-offers"
    }}>{`How a GPA implementation is selected in offers`}</h4>
    <p>{`Each `}<em parentName="p">{`GPA`}</em>{` is set up with a GPA `}<em parentName="p">{`variant`}</em>{`, through a field set on the GPA itself (`}<inlineCode parentName="p">{`comparisonOperatorVariant`}</inlineCode>{`), with values (e.g. `}<inlineCode parentName="p">{`A`}</inlineCode>{` or `}<inlineCode parentName="p">{`E`}</inlineCode>{`),
pointing to what GPA implementation is to be used for this GPA in offers.
Each `}<em parentName="p">{`element`}</em>{` (marketable/priceable object) that a GPA is attached to has set an `}<em parentName="p">{`active`}</em>{` GPA variant (`}<inlineCode parentName="p">{`activeComparisonOperatorVariant`}</inlineCode>{`)
with values (e.g.`}<inlineCode parentName="p">{`A`}</inlineCode>{` or `}<inlineCode parentName="p">{`E`}</inlineCode>{`) that decides what GPAs are to be used in offers.`}</p>
    <p>{`The diagram below shows how an implementation in offers is selected based on the variant set on each element and GPAs.`}</p>
    <p><img alt="Diagram of GPA implementation selection. Both A and E GPA variants are set on each element. E is set as the active variant. Thus E is the variant selected for evaluation." src={require("./diagram_gpa_implementation_selection.png")} /></p>
    <h4 {...{
      "id": "implementation-e"
    }}>{`Implementation E`}</h4>
    <p>{`Implementation E is the newest implementation, used for GPAs containing `}<em parentName="p">{`Scoping Validity Parameters (SVP)`}</em>{`.
The main change is how the operators are used and read for a more consistent, simple and strict setup (see `}<a parentName="p" {...{
        "href": "#operators"
      }}>{`Operators`}</a>{`).
The way unknown data types are handled in the various endpoints are also changed, with the addition of the ignore flag 'canIgnoreInOffering'
(see `}<a parentName="p" {...{
        "href": "#ignore-flag-canIgnoreInOffering"
      }}>{`Ignore flag 'canIgnoreInOffering'`}</a>{`).
Implementation E exists in parallel with implementation A for a period, so both are valid and working in offers.`}</p>
    <h4 {...{
      "id": "implementation-a"
    }}>{`Implementation A`}</h4>
    <p>{`Implementation A is the original implementation.
This implementation is still used for all Usage Parameters (UP), Temporal Validity Parameters (TVP) and some `}<em parentName="p">{`unconverted`}</em>{` Scoping Validity Parameters (SVP).`}</p>
    <h3 {...{
      "id": "placement-and-structure"
    }}>{`Placement and structure`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`GPAs can be connected to an element (marketable/priceable object): a `}<strong parentName="p">{`Fare Product`}</strong>{`, a `}<strong parentName="p">{`Sales Package`}</strong>{`, a `}<strong parentName="p">{`Validable Element`}</strong>{`,
a `}<strong parentName="p">{`Fare Structure Element`}</strong>{`, or as a `}<strong parentName="p"><em parentName="strong">{`child to another parent-GPA`}</em></strong>{` that's connected to a marketable/priceable object.
There are no fixed rules for what type of object a GPA is and can be connected to, only guidelines and best practice.`}</p>
    <p>{`If a GPA that is associated with an element (marketable/priceable object) has several GPA levels below it, it forms a tree structure where each node is a GPA.`}</p>
    <p><img alt="Example diagram of GPA structure with validity and usage parameters. One GPA contains three leaf GPAs connected to various Validity Parameters. These are combined using various logical operations. The other GPA has two leaf GPAs connected to Usage Parameters. No logical operations is performed here." src={require("./diagram_gpa_tree.png")} /></p>
    <h4 {...{
      "id": "types-of-gpa-nodes-and-what-they-can-do-and-contain"
    }}>{`Types of GPA nodes and what they can do and contain`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`In offers, the GPAs are divided into `}<strong parentName="p"><em parentName="strong">{`leaf nodes`}</em></strong>{` and `}<strong parentName="p"><em parentName="strong">{`parent nodes`}</em></strong>{`. The `}<em parentName="p">{`leaf nodes`}</em>{` are the ones that `}<em parentName="p">{`perform data validation`}</em>{`,
while `}<em parentName="p">{`parent nodes`}</em>{` only contain leaf GPA nodes or other parent nodes.
There should `}<em parentName="p">{`never`}</em>{` be nodes that are simultaneously a parent node (that have children GPAs) and a leaf node that perform data validation.`}</p>
    <p>{`GPAs that are `}<strong parentName="p">{`parent nodes`}</strong>{` only determine the kind of `}<em parentName="p">{`logical operation`}</em>{` to be performed on their leaf nodes, and on these `}<em parentName="p">{`no data validation shall occur`}</em>{`.
On the parent nodes, only the 'Includes Grouping Type' data field must contain an operator that describes how the underlying leaf nodes are to be validated.
If a GPA hierarchy contain Usage Parameters (UP), the data field for 'Includes Grouping Type' has no effect.`}</p>
    <p>{`GPAs that are `}<strong parentName="p">{`leaf nodes`}</strong>{` must contain `}<em parentName="p">{`data validation with an operator against a data type`}</em>{` that determines the prerequisites/rules for an offer to be valid.
The leaf nodes are considered as a `}<em parentName="p">{`chain of operations`}</em>{`, consisting of retrieving data values, enforcing constraints on the data values,
comparing data values from the product definition and query, examining whether the result should be ignored and finally a summary of the results that ends up with a Boolean value.
Leaf nodes perform logical operations. In `}<em parentName="p">{`Implementation A`}</em>{`, comparison operators are also performed, but in `}<em parentName="p">{`Implementation E`}</em>{` they do an `}<em parentName="p">{`implicit comparison`}</em>{` of data values (see `}<a parentName="p" {...{
        "href": "#operators"
      }}>{`Operators`}</a>{`).
Furthermore, leaf nodes may have limitations in the value space of the data values, and for some data types pre-processing of the data values is necessary.`}</p>
    <h4 {...{
      "id": "conditions-and-choices"
    }}>{`Conditions and Choices`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`A GPA is in offers considered either a "`}<em parentName="p">{`condition`}</em>{`" or a "`}<em parentName="p">{`choice`}</em>{`", or a `}<em parentName="p">{`parent`}</em>{` to other GPAs.
If a GPA is a `}<em parentName="p">{`parent GPA`}</em>{`, it includes other GPAs, that in their turn is either a "condition" or a "choice".`}</p>
    <p>{`All types of GPAs can be set as 'Required In Context', have a 'Required Type' and potentially have 'Required Elements' (for more info see `}<a parentName="p" {...{
        "href": "#context"
      }}>{`Context`}</a>{`).`}</p>
    <h5 {...{
      "id": "conditions"
    }}>{`Conditions`}</h5>
    <p>{`If a GPA contains `}<em parentName="p">{`only`}</em>{` `}<em parentName="p">{`Scoping Validity Parameters (SVP)`}</em>{` or `}<em parentName="p">{`Temporal Validity Parameters (TVP)`}</em>{` it will be a "condition".
Common to all "conditions" is that they have a validation function that takes a list of TravelScopes as an argument and `}<em parentName="p">{`returns true or false`}</em>{`.`}</p>
    <h5 {...{
      "id": "choices"
    }}>{`Choices`}</h5>
    <p>{`If a GPA contains `}<em parentName="p">{`Usage Parameters (UP)`}</em>{`, `}<em parentName="p">{`either alone or in combination with any of the validity parameters`}</em>{`, it will be a "choice".
A "choice" has options, and consists of a list of `}<em parentName="p">{`Alternatives`}</em>{` and has a method to retrieve valid alternatives based on TravelScopes and Travelers.
Each alternative is evaluated and converted to a ValidAlternative if it is valid, otherwise filtered out. If there is no valid alternative for a "choice", it becomes a "failedChoice" and it will not result in an offer.`}</p>
    <h4 {...{
      "id": "special-rules-and-prerequisites-worth-noting"
    }}>{`Special rules and prerequisites worth noting`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`A `}<em parentName="p">{`mix of Usage Parameters (UP) and the Validity Parameters (SVP & TVP)`}</em>{` is `}<em parentName="p">{`only allowed`}</em>{` when the `}<em parentName="p">{`parameters are on the same GPA`}</em>{`, meaning that the SVP/TVP is scoping the validation of the Usage Parameter.`}</p>
    <p>{`Scoping with `}<em parentName="p">{`PlaceUse`}</em>{` (when used for zones) with `}<em parentName="p">{`different PlaceUse types`}</em>{` (StartAt, Via, EndAt) `}<em parentName="p">{`cannot share the same GPA`}</em>{`. There must be separate GPAs for SVPs with different PlaceUse values.`}</p>
    <h3 {...{
      "id": "context"
    }}>{`Context`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`To enforce the `}<em parentName="p">{`fulfilment`}</em>{` of the GPA requirements in the context of an offer generating system, GPAs can be set as `}<strong parentName="p">{`Required In Context`}</strong>{`, with a specific `}<strong parentName="p">{`Required Type`}</strong>{` and with possible `}<strong parentName="p">{`Required Elements`}</strong>{`, to require the element type upon travel.
When `}<strong parentName="p"><em parentName="strong">{`'Required In Context'`}</em></strong>{` with the value `}<inlineCode parentName="p">{`OFFERING`}</inlineCode>{` is added to a GPA, offers adds a `}<strong parentName="p">{`Specific Parameter Assignment (SPA)`}</strong>{`, with the type in `}<strong parentName="p"><em parentName="strong">{`'RequiredType'`}</em></strong>{` set as limitations.`}</p>
    <p>{`The SPA assigns this parameter to the offer, and is validated when the ticket is inspected upon travel.`}</p>
    <p>{`For example, if 'Required In Context' is set to `}<inlineCode parentName="p">{`OFFERING`}</inlineCode>{` and 'Required Type' is set to `}<inlineCode parentName="p">{`DATED_SERVICE_JOURNEY_REF`}</inlineCode>{`, it returns `}<em parentName="p">{`valid`}</em>{` if the passenger is on this `}<em parentName="p">{`exact Dated Service Journey`}</em>{` upon validation.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/"
      }}>{`Swagger doc`}</a>{` for example response for `}<inlineCode parentName="p">{`specificParameterAssignments`}</inlineCode>{` in endpoint `}<inlineCode parentName="p">{`/offers/v2/{id}`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example(s)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`requiredInContext`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The context in which the validity parameter assignment must be made specific by specifying the required type.`}<br />{`Only `}<inlineCode parentName="td">{`OFFERING`}</inlineCode>{` is evaluated when generating offers.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`OFFERING`}</inlineCode>{`, `}<inlineCode parentName="td">{`BOOKING`}</inlineCode>{`, `}<inlineCode parentName="td">{`SALE`}</inlineCode>{`, `}<inlineCode parentName="td">{`ACTIVATION`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`requiredType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of class that must be specified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`USER_PROFILE_REF`}</inlineCode>{`, `}<inlineCode parentName="td">{`FARE_ZONE_REF`}</inlineCode>{`, `}<inlineCode parentName="td">{`USAGE_VALIDITY_PERIOD`}</inlineCode>{`, `}<inlineCode parentName="td">{`GROUP_TICKET`}</inlineCode>{`, `}<inlineCode parentName="td">{`DATED_SERVICE_JOURNEY_REF`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`requiredElements`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of element(s) that must be specified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`START_TIME`}</inlineCode>{`, `}<inlineCode parentName="td">{`STANDARD_DURATION`}</inlineCode>{`, `}<inlineCode parentName="td">{`START_STOP_POINT_REF`}</inlineCode>{`, `}<inlineCode parentName="td">{`END_FARE_ZONE_REF`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "data-types"
    }}>{`Data Types`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <h4 {...{
      "id": "data-types-for-usage-parameters-up"
    }}>{`Data Types for Usage Parameters (UP)`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Data Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Evaluated in offers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required in offers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example (NeTEx ID)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ChargingPolicy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:ChargingPolicy:BillAsYouGo`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanionProfile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:CompanionProfile:Infant`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EntitlementGiven`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:EntitlementGiven:Nullbillett`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EntitlementRequired`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:EntitlementRequired:levelA1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Exchanging`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Exchanging:FlexFee`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FrequencyOfUse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:FrequencyOfUse:TwiceADay`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GroupTicket`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes **`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:GroupTicket:Sleeper`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interchanging`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Interchanging:ThroughFare`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LuggageAllowance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:LuggageAllowance:Bicycle`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PenaltyPolicy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:PenaltyPolicy:InspectionFee`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PurchaseWindow`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:PurchaseWindow:StandardFromDuration`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Refunding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Refunding:NoRefund`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Replacing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Replacing:NoReplacing`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reselling`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Reselling:NoReselling`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reserving`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Reserving:CompulsoryRes`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RoundTrip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:RoundTrip:TrainRoundTrip`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SecurityPolicy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:SecurityPolicy:HighLevel`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transferability`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Transferability:AllowTransfer`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UsageValidityPeriod`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:UsageValidityPeriod:1Zone`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UserProfile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes **`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:UserProfile:Adult`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <sub> * Evaluated for validity, but there is no requirement that these must exist a product for it to be offered.</sub>
    <br />
    <sub> ** Either UserProfile or Group are implicitly required, since later we try to assign sales packages to travelers,
    and remove those that have not had any travelers assigned to them.</sub><br />
    <br />
    <h4 {...{
      "id": "data-types-for-temporal-validity-parameters-tvp"
    }}>{`Data Types for Temporal Validity Parameters (TVP)`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p><strong parentName="p">{`Temporal Validity Parameters (TVP)`}</strong>{` is reflecting `}<em parentName="p">{`temporal limitations`}</em>{` (when e.g. a product may be used).
They contain Validity Conditions like `}<strong parentName="p">{`'Valid Between'`}</strong>{` `}<em parentName="p">{`(to and from dates of which the travel can happen between)`}</em>{`, `}<strong parentName="p">{`'Day Type'`}</strong>{` `}<em parentName="p">{`(during what type of day the trip can happen)`}</em>{` and `}<strong parentName="p">{`'Timeband'`}</strong>{` `}<em parentName="p">{`(during what time the trip can happen)`}</em>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Data Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Evaluated in offers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required in offers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example (NeTEx ID)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DayType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:DayType:abc123`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Timeband`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Timeband:abc123`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ValidBetween`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:ValidBetween:abc123`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <br />
    <h4 {...{
      "id": "data-types-for-scoping-validity-parameters-svp"
    }}>{`Data Types for Scoping Validity Parameters (SVP)`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`We have chosen to divide the SVP data types into "simple data types" and "complex data types" in offers, because it is good to be aware that some data types are slightly more complex and can have their own setups, rules and exceptions.
The "simple" data types only contain a text element, either a netex ID or the name of an enum value, while the "Complex" ones have slightly more complex and varied values and associations.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Data Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Evaluated in offers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required in offers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Complexity`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Authority:ABC`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DistributionChannel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ENT:DistributionChannel:Web`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:Line:2_800`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VehicleMode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`RAIL`}</inlineCode>{`, `}<inlineCode parentName="td">{`BUS`}</inlineCode>{`, `}<inlineCode parentName="td">{`WATER`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TransportSubmode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`regionalRail`}</inlineCode>{`, `}<inlineCode parentName="td">{`local`}</inlineCode>{`, `}<inlineCode parentName="td">{`nightBus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FacilitySet *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:ServiceFacilitySet:Seating`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FareClass *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`STANDARD_CLASS`}</inlineCode>{`, `}<inlineCode parentName="td">{`PREMIUM_CLASS`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`35`}</inlineCode>{`, `}<inlineCode parentName="td">{`57`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FareZones / tariffZones`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Complex`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:FareZone:123`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FareSection`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Complex`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABC:FareSection:123`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ScheduledStopPoint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Complex`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NSR:StopPlace:374`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TopographicPlace`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Complex`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LAN:TopographicPlace:123`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GroupOfLines **`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GroupOfTariffZones **`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <sub>* No endpoints currently have any prerequisites for evaluating these.</sub><br />
    <sub>** Not yet implemented.</sub><br />
    <h4 {...{
      "id": "data-types-that-are-handled-in-the-various-endpoints"
    }}>{`Data Types that are handled in the various endpoints`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`There are several different endpoints for generating offers, and they receive different information in requests from clients.
`}<inlineCode parentName="p">{`DistributionChannel`}</inlineCode>{` must always be provided by the client when making a request.
`}<inlineCode parentName="p">{`Authority`}</inlineCode>{` is derived either from the request directly, from the journey pattern or from the FareZone.
All endpoints handle both `}<inlineCode parentName="p">{`Authority`}</inlineCode>{` and `}<inlineCode parentName="p">{`DistributionChannel`}</inlineCode>{`.
Some of the endpoints have implemented functionality to derive more data types than others, see table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Endpoint`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Additional data types the endpoint handles`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Additional data sources where values are collected from`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/search/trip-pattern`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All data types, `}<em parentName="td">{`except FacilitySet, FareClass and Operator`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Information taken from the specific departure (journey pattern)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/search/zones`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FareZone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/search/stop-places`}</inlineCode>{` `}<br /><em parentName="td">{`(for trains)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FareSection`}<br />{`Line`}<br />{`ScheduledStopPoint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Detected journey along the rail network`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/search/stop-places`}</inlineCode>{` `}<br /><em parentName="td">{`(for other modes than trains)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority from owner of DME matrix for OriginDestination-products`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/search/authority`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "operators"
    }}>{`Operators`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Assignments may be combined using logical operators and comparison operators to create composite assignments.
GPAs that's on `}<a parentName="p" {...{
        "href": "#implementations"
      }}>{`Implementation E`}</a>{` does not use comparison operators (`}<em parentName="p">{`EQ`}</em>{` is implicitly used).`}</p>
    <h4 {...{
      "id": "fields-using-operators"
    }}>{`Fields using operators`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Data Field containing operator`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type of operator`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`What it validates`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`includesGroupingType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`logical operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Describes how the `}<strong parentName="td"><em parentName="strong">{`leaf/child GPA nodes`}</em></strong>{` of a `}<strong parentName="td">{`parent GPA node`}</strong>{` are to be validated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`limitationGroupingType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`logical operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Describes data validation of underlying `}<strong parentName="td"><em parentName="strong">{`Usage Parameters (UP)`}</em></strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`validityParameterGroupingType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`logical operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Describes data validation of underlying `}<strong parentName="td"><em parentName="strong">{`Scoping (SVP)`}</em></strong>{` and `}<strong parentName="td"><em parentName="strong">{`Temporal Parameter Assignments (TPA)`}</em></strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`validityParameterAssignmentType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`comparison operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Describes data validation of underlying `}<strong parentName="td"><em parentName="strong">{`Scoping (SVP)`}</em></strong>{` and `}<strong parentName="td"><em parentName="strong">{`Temporal Parameter Assignments (TPA)`}</em></strong></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "logical-operators"
    }}>{`Logical operators`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Logical operator`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notation of function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported for SVP`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported for TVP`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported for UP`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AND`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><CodeText mdxType="CodeText">{`Q == P`}</CodeText><br /><em parentName="td">{`(Q equals P)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The elements in the query (Q) and in the product definition (P) are equal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`OR`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><CodeText mdxType="CodeText">{`Q ⊆ P  &&  `}{`|`}{`Q`}{`|`}{` > 0`}</CodeText><br /><em parentName="td">{`(Q is a subset of P and the number of elements in Q is more than 0)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All elements in the query (Q) must also be found in the product definition (P)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes *`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`XOR`}</strong>{` `}<em parentName="td">{`(Exclusive OR)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><CodeText mdxType="CodeText">{`Q ⊆ P  &&  `}{`|`}{`Q`}{`|`}{` == 1`}</CodeText><br /><em parentName="td">{`(Q is a subset of P and the number of elements in Q must be 1)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is only one element in the query (Q) and it must exist in the product definition (P)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`NOT`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><CodeText mdxType="CodeText">{`Q ∩ P == ∅`}</CodeText><br /><em parentName="td">{`(Q and P have no common elements)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There are no items in the query (Q) that exists in the product definition (P)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
        </tr>
      </tbody>
    </table>
    <sub> Q = query, P = product definition </sub><br />
    <sub>* GPAs containing Usage Parameters (UP) will only use the operator 'OR'.</sub>
    <h4 {...{
      "id": "comparison-operators"
    }}>{`Comparison operators`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Comparison operator`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported for SVP `}<em parentName="th">{`(implementation A)`}</em></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported for SVP `}<em parentName="th">{`(implementation E)`}</em></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported for TVP`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported for UP`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`EQ`}</strong>{` `}<em parentName="td">{`(Equal)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter value must be equal to the specified element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`NE`}</strong>{` `}<em parentName="td">{`(Not equal)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter value cannot be equal to the specified element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`GT`}</strong>{` `}<em parentName="td">{`(Greater than)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter value must be greater than the specified element.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`GE`}</strong>{` `}<em parentName="td">{`(Greater than or equal to)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter value must be greater than or equal to the specified element.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`LT`}</strong>{` `}<em parentName="td">{`(Less than)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter value must be less than the specified element.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`LE`}</strong>{` `}<em parentName="td">{`(Less than or equal to)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter value must be less than or equal to the specified element.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓ Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✕ No`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "ignore-flag-canignoreinoffering"
    }}>{`Ignore flag 'canIgnoreInOffering'`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`The ignore flag is set per GPA, as a boolean field (`}<inlineCode parentName="p">{`can_ignore_in_offering`}</inlineCode>{`). Only supported by `}<a parentName="p" {...{
        "href": "#implementations"
      }}>{`Implementation E`}</a>{`. Setting a GPA to be ignored in offering should mean "does not contribute to the calculation of the result".`}</p>
    <p>{`Data types not found in an endpoint are handled as an empty set in evaluation. An empty set with the operators AND, OR or XOR will mean that you will not receive an offer.
E.g. a zone-based product with `}<inlineCode parentName="p">{`OR [localBus, metro]`}</inlineCode>{` will not be offered in the `}<inlineCode parentName="p">{`/search/zones`}</inlineCode>{` endpoint because it cannot derive transportSubmode, because the endpoint handles it as `}<inlineCode parentName="p">{`OR [{}, {}]`}</inlineCode>{`.`}</p>
    <p>{`If there is a missing data value for a given data type in the offers search, then the evaluation of the data type and the values must be `}<strong parentName="p">{`set to ignored in the GPA`}</strong>{` so that an offer can still be made in endpoints that does not evaluate them.`}</p>
    <p>{`The ignore flag `}<em parentName="p">{`should not`}</em>{` be allowed to be set on Authority or DistributionChannel (but always set on FareClass and FacilitySet, due to them not yet being evaluated in any endpoint).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      